import React from 'react'
import {useRef, useEffect} from 'react'
import "./intro.css"
import Me from "../../img/me.png"
import {gsap, Power3, Power2} from "gsap"

const Intro = () => {
    let introApp = useRef(null)
    let intro = useRef(null);
    let name = useRef(null);
    let title = useRef(null);
    let desc = useRef(null);
    let imageContainer = useRef(null);
    let image = useRef(null);
    let tl = gsap.timeline()

    useEffect(() => {
        tl.to(introApp, {duration: 0, css: {visibility: "visible"}})
        .from([intro, name, title, desc], {duration: 1, y: "-100px", opacity: 0, ease: Power3.easeOut, stagger: .2});
        
        
        // gsap.from([intro, name, title, desc], {duration: 1, y: "100px", opacity: 0, ease: Power3.easeOut, stagger: .1});
        gsap.from(imageContainer, {duration: 1, opacity: 0, ease:Power3.easeInOut, delay: 0.5})
        gsap.from(image, {duration: 1.5})
    },[])
  return (
    <div className='i' ref={el => introApp = el}>
        <div className='i__left'>
            <div className="i__left__wrapper">
                <h2 className='i__intro' ref={el => intro = el}>Hello, My name is</h2>
                <h1 className='i__name' ref={el => name = el}>Stephan Nishigori</h1>
                <div className="i__title" ref={el => title = el}>
                    <div className="i__title__wrapper">
                        <div className="i__title__item">Web Developer</div>
                        <div className="i__title__item">Software Engineer</div>
                        <div className="i__title__item">React Developer</div>
                        <div className="i__title__item">Sass Enthusiast</div>
                        <div className="i__title__item">Gsap Animations</div>
                    </div>
                </div>
                <p className="i__desc" ref={el => desc = el}>
                Your talent determines what you can do. 
                Your motivation determines how much you’re willing to do. 
                Your attitude determines how well you do it.
                <br />- LOU HOLTZ
                </p>
            </div>
        </div>
        <div className='i__right' ref={el => imageContainer = el}>
            <div className='i__bg' ref={el => image = el}></div>
            {/* <img src={Me} alt="" className="i__img" /> */}
        </div>
    </div>
  )
}

export default Intro