import {React, useEffect, useRef } from 'react'
import "./productList.css"
import Product from "../product/Product"
import {products} from "../../data"
import {gsap, Power3} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";



const ProductList = () => {
  gsap.registerPlugin(ScrollTrigger);

  let word1 = useRef(null)
  let word2 = useRef(null)
  let word3 = useRef(null)
  let word4 = useRef(null)
  let word5 = useRef(null)
  let pl = useRef(null)
  let plDesc = useRef(null)

  useEffect(() => {
    gsap.from([word1, word2, word3, word4, word5, plDesc], {duration: .8, opacity: 0, stagger: 0.3, scrollTrigger: word1})
  })

  return (
    <div className='pl' ref={el => pl = el}>
        <div className="pl__texts">
        <h1 className="pl__title"><span ref={el => word1 = el}>Past</span> <span ref={el => word2 = el}>websites</span> <span ref={el => word3 = el}>I</span> <span ref={el => word4 = el}>have</span> <span ref={el => word5 = el}>built!</span></h1>
        <p className="pl__desc" ref={el => plDesc = el}>
          I have built many websites but these are a few of them that I would love to share!
        </p>
        </div>
        <div className="pl__list">
            {products.map((item) => (<Product key={item.id} img={item.img} link={item.link} delay={item.delay}/>))}
        </div>
    </div>
  )
}

export default ProductList