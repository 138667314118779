import {React, useRef, useEffect} from 'react'
import './about.css'
import Award from "../../img/award.png"
import ReactImg from "../../img/carbon (1).png"
import {gsap, Power3, Power2} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

const About = () => {
  gsap.registerPlugin(ScrollTrigger)

  let aboutCard = useRef(null)
  let aboutCardBg = useRef(null)
  let aboutTitle = useRef(null)
  let aboutSub = useRef(null)
  let aboutDesc = useRef(null)
  let aboutReact = useRef(null)
  let aboutCertificate = useRef(null)
  
  useEffect(() => {
    gsap.from([aboutCardBg, aboutCard], {duration: 1.3, opacity: 0, y: -30, ease:Power3.easeOut, stagger: 0.4, scrollTrigger: aboutCard})
    gsap.from([aboutTitle, aboutSub, aboutDesc], {duration: 1.3, opacity: 0, ease:Power3.easeOut, stagger: 0.4, scrollTrigger: aboutDesc})
    // gsap.from(aboutReact, {duration: 0.7, opacity: 0, y: 30, ease:Power2.easeIn, scrollTrigger: aboutReact, delay: 0.3})
    // gsap.from(aboutCertificate, {duration: 0.7, opacity: 0, y: 30, ease: Power2.easeIn, scrollTrigger: aboutReact, delay: 0.3})
  },[])

  return (
    <div className='a'>
        <div className="a__left">
            <div className="a__card bg" ref={el => aboutCardBg = el}></div>
            <div className="a__card" ref={el => aboutCard = el}>
                <img src={ReactImg} alt="" className="a__img" />
            </div>
        </div>
        <div className="a__right">
        <h1 className="a__title" ref={el => aboutTitle = el}>About Me</h1>
        <p className="a__sub" ref={el => aboutSub = el}>
          I am a Web Developer who loves building beautiful websites from beautiful designs.
        </p>
        <p className="a__desc" ref={el => aboutDesc = el}>
          I am a Web Developer who always wants to learn and improve. I have knowledge
          in many different front end utilities such as ReactJS, Javascript, TypeScript,
          Python, HTML, CSS, and Sass. I am a self taught programmer from the age of 15
          and have taken a real life class on JavaScript. I learned most of my coding
          from online courses such as Code With Mosh, Scrimba and FreeCodeCamp.
        </p>
        <div className="a__award">
          <img src={Award} alt="" className="a__award__img" ref={el => aboutReact = el}/>
          <div className="a__award__texts" ref={el => aboutCertificate = el}>
            <h4 className="a__award__title">12 Hours React Course Completion</h4>
            <p className="a__award__desc">
              <a className='a__award__link' href="https://i.ibb.co/xSQ051t/Screen-Shot-2022-02-24-at-20-07-48.png" target="_blank">Click here to see my proof of completion!</a>
            </p>
          </div>
        </div>
        </div>
    </div>
  )
}

export default About