import React, { useContext, useRef, useEffect } from 'react'
import "./contact.css"
import Phone from "../../img/phone.png"
import Email from "../../img/email.png"
import Address from "../../img/address.png"
import emailjs from '@emailjs/browser'
import { ThemeContext } from '../../context'
import {gsap, Power3} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Contact = () => {
    gsap.registerPlugin(ScrollTrigger);
    const formRef = useRef()
    const [done, setDone] = React.useState(false)
    const theme = useContext(ThemeContext)
    const darkMode = theme.state.darkMode;

    let leftContact = useRef(null)
    let rightContact = useRef(null)

    useEffect(() => {
      gsap.from([leftContact, rightContact], {duration: 1, opacity: 0, y: 30, ease: Power3.easeOut, stagger: 0.5, scrollTrigger: leftContact})
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_kmnlw3i', 'template_s59hkdv', formRef.current, 'user_6jKNcndUxQ7zNXKDWZWQ0')
        .then((result) => {
            console.log(result.text);
            setDone(true)
        }, (error) => {
            console.log(error.text);
        });
    }

  return (
    <div className='c'>
        <div className="c__bg"></div>
        <div className="c__wrapper">
            <div className="c__left" ref={el => leftContact = el}>
                <h1 className="c__title">Get in touch!</h1>
                <div className="c__info">
                    <div className="c__info__item">
                        <img src={Phone} alt="" className="c__icon" />
                        080 7982 1888
                    </div>
                </div>
                <div className="c__info__item">
              <img className="c__icon" src={Email} alt="" />
              StephanNishigorii@gmail.com
            </div>
            <div className="c__info__item">
              <img className="c__icon" src={Address} alt="" />
              Japan
            </div>
            </div>
            <div className="c__right" ref={el => rightContact = el}>
            <p className="c__desc">
            <b>Get in touch!</b> I am always excited for new oppurtunities to learn, grow and to work with a team!
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
              <input style={{backgroundColor: darkMode && "#333", color: darkMode && "white"}} type="text" placeholder='Name' name='user_name'/>
              <input style={{backgroundColor: darkMode && "#333", color: darkMode && "white"}} type="text" placeholder='Subject' name='user_subject' required/>
              <input style={{backgroundColor: darkMode && "#333", color: darkMode && "white"}} type="text" placeholder='Email' name='user_email' required/>
              <textarea style={{backgroundColor: darkMode && "#333", color: darkMode && "white"}} rows="5" placeholder='Message' name='message'/>
              <button>Submit</button>
              {done && <span className='submitted'>Thank you!</span>}
          </form>
            </div>
        </div>
    </div>
  )
}

export default Contact