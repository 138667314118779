import React, { useEffect, useRef } from 'react'
import './product.css'
import {gsap, Power3} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Product = (props) => {
  gsap.registerPlugin(ScrollTrigger);

  let browser = useRef(null)

  // useEffect(() => {
  //   gsap.from(browser, {duration: 1, opacity: 0, y: 30, ease: Power3.easeInOut, scrollTrigger: browser, delay: props.delay})
  // })
  return (
    <div className='p' ref={el => browser = el}>
        <div className="p__browser">
            <div className="p__circle"></div>
            <div className="p__circle"></div>
            <div className="p__circle"></div>
        </div>
        <a href={props.link} target='_blank' rel='norefferer'>
            <img src={props.img} alt="" className="p__img" />
        </a>
    </div>
  )
}

export default Product