export const products = [
    {
      id: 1,
      img: "https://i.ibb.co/LNwpdVG/screencapture-fylo-vercel-vercel-app-2022-02-23-20-45-43.png",
      link: "https://fylo-vercel.vercel.app/?",
      delay: 0.3
    },
    {
      id: 2,
      img: "https://i.ibb.co/mN97F9v/screencapture-sunnyside-landing-page-herokuapp-2022-03-02-21-39-21.png",
      link: "https://sunnyside-react.pages.dev/",
      delay: 0.6
    },
    {
      id: 3,
      img: "https://i.ibb.co/MPwCcKk/screencapture-clip-board-landing-page-nine-vercel-app-2022-02-24-19-22-44.png",
      link: "https://clip-board-landing-page-nine.vercel.app/",
      delay: 0.9
    },
    {
      id: 4,
      img: "https://i.ibb.co/WsSW6SN/mobile-design.jpg",
      link: "https://order-summary-livid.vercel.app/",
      delay: 0.3
    },
    {
      id: 5,
      img: "https://i.ibb.co/C0KPK85/mobile-design.jpg",
      link: "https://four-card-plum.vercel.app/",
      delay: 0.6
    },
    {
      id: 6,
      img: "https://i.ibb.co/87H94bT/screencapture-3-column-preview-six-vercel-app-2022-02-24-21-07-42.png",
      link: "https://3-column-preview-six.vercel.app/",
      delay: 0.9
    },
  ];