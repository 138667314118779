import React, { useContext } from 'react'
import "./toggle.css"
import Sun from '../../img/sun.png'
import Moon from '../../img/moon.png'
import { ThemeContext } from '../../context'

const Toggle = () => {
  const theme = useContext(ThemeContext);

  const handleClick = () => {
    theme.dispatch({type:"TOGGLE"})
  }
  return (
    <></>
  )
}

export default Toggle